import { alpha, Container, Paper, Typography } from '@mui/material';
import { Invoice } from '../../models/Invoice';

interface Props {
  invoice: Invoice;
}

export const Head = ({ invoice }: Props) => {
  return (
    <Container maxWidth='xs'>
      {invoice.placeIntro.logo && (
        <Container disableGutters maxWidth='xs' sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <img style={{ maxWidth: '90%' }} alt='logo' aria-label='logo' src={invoice.placeIntro.logo} />
        </Container>
      )}
      <Typography
        variant='h5'
        textAlign='center'
        sx={{
          pt: 5,
        }}
      >{`${invoice.placeIntro.placeName}`}</Typography>
      <Typography
        variant='body2'
        textAlign='center'
        sx={{
          pt: 2,
        }}
      >{`${invoice.placeIntro.businessNumber} | ${invoice.placeIntro.phoneNumber}`}</Typography>
      <Typography
        variant='body1'
        textAlign='center'
        sx={{
          py: 2,
        }}
      >{`${invoice.payment.paymentTime}`}</Typography>
      <Typography
        variant='body1'
        textAlign='center'
        sx={{
          py: 2,
        }}
      >
        {invoice.isInvoice ? `חשבונית מס/קבלה ${invoice.invoiceId}` : `חשבונית/קבלה`}
      </Typography>
      <Paper
        className='right'
        elevation={0}
        sx={{
          background: 'linear-gradient(15deg, rgba(0,61,51,1) 50%, rgba(57,121,107,1) 100%)',
          my: 1,
          p: 2.5,
          color: alpha('#FFF', 0.95),
        }}
      >
        <Typography variant='h4' textAlign='center'>{`הזמנה ${invoice.orderId}`}</Typography>
      </Paper>
    </Container>
  );
};
